<template>
  <div class="photo_wall">
    <!-- <common-nav /> -->
    <div class="wrap photo_wall_content">
      <div class="left_box">
        <div v-for="(item, index) in leftNav" :key="index" class="left_nav_box" :class="{selected: item.index === photoType}" @click="toContent(item)">{{item.name}}</div>
      </div>
      <div class="right_box">
        <div v-if="pageNum != 0" class="prev" @click="prevImg">
          <i class="iconfont iconxiayiye"></i>
        </div>
        <div v-if="!(pageNum >= Math.ceil(total / pageSize - 1))" class="next" @click="nextImg">
          <i class="iconfont iconxiayiye"></i>
        </div>
        <div v-for="(item, index) in showPhotoList" :Key="index" class="photo_box">
          <div v-if="item.photoUrl" class="img_box">
            <img v-if="item.encryption == 0 || userName == 'zhouyichang' || showPhoto" class="photo" :src="item.photoUrl" alt="">
            <div v-if="!showPhoto && item.encryption == 1 && userName != 'zhouyichang'" class="suo_box">
              <div>
                <i class="iconfont iconbaoqian"></i>
                <div class="baoqian_text">
                  此图已上锁
                </div>
              </div>
            </div>
            <div class="action_box">
              <div v-if="(userName !== 'zhouyichang') && item.encryption != 0" class="pass_word_box">
                <input type="password" class="psd" v-model="passWord">
              </div>
              <div v-if="item.content" class="content" :title="item.content">{{ item.content }}</div>
              <div v-if="userName == 'zhouyichang'" class="action">
                <i class="iconfont iconshanchu" @click="removePhoto(item)"></i>
                <i class="iconfont iconedit"></i>
              </div>
              <div v-if="(userName !== 'zhouyichang') && item.encryption != 0" class="action">
                <i class="iconfont icon24px" @click="requestPhoto(item)"></i>
              </div>
            </div>
          </div>
          <div v-if="!item.photoUrl && userName == 'zhouyichang'" class="img_box img_logo_box">
            <i class="iconfont iconcameraadd"></i>
            <input class="img_logo_input" type="file" @change="imgChange">
          </div>
        </div>
      </div>
    </div>
    <div class="show_box blur" v-show="showAdd"></div>
    <div class="show_box" v-show="showAdd">
      <div class="add_box">
        <div class="photo_text">
          <img class="photo" :src="photoUrl" alt="">
          <div class="others">
            <label for="photoTime">
              <span>照片时间：</span>
              <input id="photoTime" type="date" v-model="photoTime" @change="timeChange">
            </label>
            <div>
              <span>是否加密：</span>
              <label for="yes">
                <input id="yes" type="radio" value=1 v-model="encryption">
                是
              </label>
              <label for="no">
                <input id="no" type="radio" value=0 v-model="encryption">
                否
              </label>
            </div>
            <textarea ref="myWord" v-if="userName == 'zhouyichang'" v-model="photoContent" class="area" name="" id="" placeholder="记下你的故事……"></textarea>
            <textarea v-else v-model="photoContent" class="area" name="" id="" disabled readonly></textarea>
          </div>
        </div>
        <div class="btn_box">
          <div class="btn" @click="addPhoto">上传</div>
          <div class="btn" @click="closePhoto">关闭</div>
        </div>
      </div>
    </div>
    <pop
      ref="addTitle"
      :title="finalMes"
      cancel-text="取消"
      :show-sure=false
      @cancel="cancel" />
      <pop
      ref="delTitle"
      title="是否确认删除？"
      sure-text="删除"
      cancel-text="取消"
      @sure="delPhoto"
      @cancel="delCancel" />
  </div>
</template>

<script>
  // import commonNav from '@/component/commonNav'
  // uploadMyPhoto, removeMyPhoto, deleteImg,
  import pop from '@/component/pop/pop.vue'
  import { uploadPublicPhoto, uploadMyPhoto, removePublicPhoto, removeMyPhoto } from '@/vue_api/upload.js'
  import { allPhoto, addPhoto, delPhoto } from '@/vue_api/photo.js'
  export default {
    name: 'photoWall',
    components: {
      // commonNav,
      pop
    },
    data() {
      return{
        finalMes: '',   //  弹窗提示语
        leftNav: [
          {
            name: '嘿嘿大家',
            index: 0
          },
          {
            name: '哈哈是我',
            index: 1
          }
        ],
        photoUrl: '',
        passWord: '',
        pageNum: 0,
        photoFile: {},  //  要删除的照片对象
        photoTime: '',
        photoType: 0, //  0：大家，1：我
        encryption: 0,  //  0: 不加密 。1：加密
        showAdd: false,
        showPhoto: false,
        total: 0,
        pageSize: 20,
        photoList: [],
        photoContent: '',
        showPhotoList: [{id: null}] //  第一个空对象，用于放添加方块
      }
    },
    mounted() {
      this.allPhoto()
    },
    methods: {
      nextImg() {
        if(this.pageNum >= Math.ceil(this.total / this.pageSize - 1)) { 
          return
        }
        this.pageNum++
        this.allPhoto()
      },
      prevImg() {
        if(this.pageNum == 0) { return }
        this.pageNum--
        this.allPhoto()
      },
      cancel() {
        this.$refs.addTitle.close()
      },
      timeChange(e) {
        console.log('--------', e, this.photoTime);
      },
      toContent(item) {
        console.log('点击了侧边栏', item.index)
        this.photoType = item.index
        this.allPhoto()
      },
      // 请求解锁照片
      requestPhoto(fs) {
        console.log('requestPhoto---------', fs)
        // this.$nextTick(() => {
          this.allPhoto()
        // })
      },
      // 查看所有照片
      async allPhoto () {
        const params = {
          photoType: this.photoType,  // 0：公共；1：我的
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          editor: this.userName,
          passWord: this.passWord
        }
        await allPhoto(params).then(res => {
          console.log('--------', res)
          this.photoList = []
          this.showPhotoList = [{id: null}]
          this.photoList = res.data.photoList
          this.showPhoto = res.data.showAll
          this.total = res.data.total
          if (this.userName == 'zhouyichang') {
            this.showPhotoList = this.showPhotoList.concat(this.photoList)
          } else {
            this.showPhotoList = this.photoList
          }
        })
      },
      //  上传图片
      async imgChange(e) {
        console.log('imgChange=====', e.target.files[0].size)
        this.photoUrl = ''
        if (!e.target.files[0].size) {return}
        const file = e.target.files[0]
        e.preventDefault()
        let formData = new FormData();
        formData.append('file', file)
        console.log('即将上传图片====', formData, file)
        const upload = this.photoType === 0 ? uploadPublicPhoto : uploadMyPhoto
        await upload(formData).then(res =>{
          console.log('上传图片完成====', res.data)
          if(res && res.data && res.data.status) {
            this.$nextTick(() =>{
              this.photoUrl = res.data.url
              console.log('img相关', this.photoUrl)
              this.imgStatus = '照片添加成功~'
              this.showAdd = true
            })
          } else {
            this.$nextTick(() =>{
              this.imgStatus = '照片添加失败啦！'
            })
          }
        }).catch(err => {
          console.log('上传失败了====', err)
        })
      },
      // 添加照片
      addPhoto () {
        const params = {
          content: this.photoContent,
          editor: this.userName,
          photoUrl: this.photoUrl,
          photoType: this.photoType,
          photoTime: this.photoTime,
          encryption: Number(this.encryption)
        }
        console.log('addPhoto------', this.encryption, params)
        addPhoto(params).then( res => {
          console.log('--------', res)
          this.showAdd = false
          this.allPhoto()
        })
      },
      // 选择照片之后不上传，关闭
      closePhoto() {
        this.showAdd = false
        const fileName = this.photoUrl.split('/').pop()
        const removePhoto = this.photoType === 0 ? removePublicPhoto : removeMyPhoto
        removePhoto({fileName: fileName}).then(res => {
          console.log('res-------', res)
        })
      },
      // 删除照片弹窗
      removePhoto (fs) {
        if (!(fs && fs.photoUrl && fs.id)) {
          this.finalMes = '抱歉，获取不到图片的基本信息'
          this.$refs.addTitle.open()
          return
        }
        this.photoFile = fs
        this.$refs.delTitle.open()
      },
      // 删除照片逻辑
      delPhoto() {
        console.log('filename----', this.photoFile)
        const fileName = this.photoFile.photoUrl.split('/').pop()
        const removePhoto = this.photoType === 0 ? removePublicPhoto : removeMyPhoto
        removePhoto({fileName: fileName}).then(res => {
          console.log('res-------', res)
        })
        delPhoto({id: this.photoFile.id}).then( res => {
          console.log('delPhoto------', res)
          this.allPhoto()
        })
        this.$refs.delTitle.close()
      },
      delCancel() {
        this.$refs.delTitle.close()
      }
    }
  }
</script>

<style lang="scss" scoped>
@import './index.scss'
</style>