import baseApi from './base_api.js'

//  查询照片
export function allPhoto(options) {
  return baseApi.post('/allPhoto', {...options})
}

// 增加照片
export function addPhoto(options) {
  return baseApi.post('/addPhoto', {...options})
}
  
// 删除照片记录
export function delPhoto(options) {
  return baseApi.post('/delPhoto', {...options})
}